import React from 'react'
import styled, { css, keyframes } from 'styled-components'
import { useChainIdManager, useDarkModeManager } from '../../contexts/LocalStorage'
import { TYPE } from '../../Theme'

const pulse = keyframes`
  0% { transform: scale(1); }
  60% { transform: scale(1.1); }
  100% { transform: scale(1); }
`

const Wrapper = styled.div`
  pointer-events: none;
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;

  ${(props) =>
    props.fill && !props.height
      ? css`
          height: 100vh;
        `
      : css`
          height: 180px;
        `}
`

const AnimatedImg = styled.div`
  animation: ${pulse} 800ms linear infinite;
  & > * {
    width: 200px;
  }
`

const HeaderText = styled.div`
  margin-top: -1.0rem;
  margin-left: 1.7rem;
  font-size: 0.825rem;
  font-weight: 500;
  opacity: 1.0;
  color: white;
  display: flex;
  justify-content: center;
`

const chainIdMap = {
  530: 'F(x)Core',
  7000: 'Zetachain'
}

const LocalLoader = ({ fill }) => {
  const [darkMode] = useDarkModeManager()
  const [chainId] = useChainIdManager()
  return (
    <Wrapper fill={fill}>
      <AnimatedImg>
        <img
          src={require(darkMode ? '../../assets/marginX_Logo-Dark.svg' : '../../assets/marginX_Logo-Light.svg')}
          alt="loading-icon"
        />
        <HeaderText>
          <TYPE.main fontSize={'0.825rem'}>
            {chainIdMap[chainId]}
          </TYPE.main>
        </HeaderText>
      </AnimatedImg>
    </Wrapper>
  )
}

export default LocalLoader
