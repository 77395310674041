import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { isAddress } from '../../utils/index.js'
import EthereumLogo from '../../assets/eth.png'
import zetachainLogo from '../../assets/zetachain.svg'
import fxchainLogo from '../../assets/fxchain.webp'
import { useChainIdManager } from '../../contexts/LocalStorage.js'

const BAD_IMAGES = {}

const Inline = styled.div`
  display: flex;
  align-items: center;
  align-self: center;
`

const Image = styled.img`
  width: ${({ size }) => size};
  height: ${({ size }) => size};
  background-color: white;
  border-radius: 50%;
  box-shadow: 0px 6px 10px rgba(0, 0, 0, 0.075);
`

const StyledEthereumLogo = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;

  > img {
    width: ${({ size }) => size};
    height: ${({ size }) => size};
  }
`

export default function TokenLogo({ address, header = false, size = '24px', ...rest }) {
  const [error, setError] = useState(false)
  const [chainId] = useChainIdManager()
  useEffect(() => {
    setError(false)
  }, [address])

  if (error || BAD_IMAGES[address]) {
    return (
      <Inline>
        <span {...rest} alt={''} style={{ fontSize: size }} role="img" aria-label="face">
          🤔
        </span>
      </Inline>
    )
  }
  let path
  if (chainId === 7000) {
    path = `https://raw.githubusercontent.com/FunctionX-SG/MarginX-TokenList/main/Tokens/${isAddress(address)}/logo-zeta.png`
  } else {
    path = `https://raw.githubusercontent.com/FunctionX-SG/MarginX-TokenList/main/Tokens/${isAddress(address)}/logo.png`
  }


  return (
    <Inline>
      <Image
        {...rest}
        alt={''}
        src={path}
        size={size}
        onError={(event) => {
          BAD_IMAGES[address] = true
          setError(true)
          event.preventDefault()
        }}
      />
    </Inline>
  )
}

export function ChainLogo({ chainId, size = '20px', ...rest }) {
  if (chainId === 7000) {
    return (
      <Inline>
        <StyledEthereumLogo {...rest} size={size}>
          <img src={zetachainLogo} alt="ZetaChain" style={{ borderRadius: '52%' }} />
        </StyledEthereumLogo>
      </Inline>
    )
  } else {
    return (
      <Inline>
        <StyledEthereumLogo {...rest} size={size}>
          <img src={fxchainLogo} alt="fx" style={{ borderRadius: '52%' }} />
        </StyledEthereumLogo>
      </Inline>
    )
  }
}