import { ApolloClient } from 'apollo-client'
import { InMemoryCache } from 'apollo-cache-inmemory'
import { HttpLink } from 'apollo-link-http'
import { ApolloLink, from } from "apollo-link";
import { RetryLink } from 'apollo-link-retry';
import { onError } from "apollo-link-error";

const authMiddleware = new ApolloLink((operation, forward) => {
  // add the authorization to the headers
  const token = "clz84bti0kyyt01tldgxrdmt6"
  operation.setContext({
    headers: {
      authorization: token ? `Bearer ${token}` : "",
    },
  });
  return forward(operation);
});

const retryLink = new RetryLink({
  delay: {
    initial: 10000, // Initial delay in ms
    max: 15000, // Max delay in ms
    jitter: true, // Randomize delay
  },
  attempts: {
    max: 5, // Max number of attempts
    retryIf: (error, _operation) => !!error
  },
});

const errorLink = onError(({ graphQLErrors, networkError }) => {
  if (graphQLErrors)
    graphQLErrors.forEach(({ message, locations, path }) =>
      console.log(
        `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`
      )
    );
  if (networkError) console.error(`[Network error]: ${networkError}`);
});


export const clientFX = new ApolloClient({
  link: from([retryLink, errorLink, new HttpLink({
    uri: 'https://graph-node.functionx.io/subgraphs/name/subgraphMX',
    // uri: 'https://api.goldsky.com/api/public/project_clz6ti9bm1qjm01086dflgsh3/subgraphs/mx-subgraph/1.0.0/gn',
  })]),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const clientZETA = new ApolloClient({
  link: from([retryLink, errorLink, authMiddleware, new HttpLink({
    // uri: 'https://graph-node.functionx.io/subgraphs/name/subgraphMX',
    uri: 'https://api.goldsky.com/api/private/project_clz6ti9bm1qjm01086dflgsh3/subgraphs/mx-subgraph/1.0.0/gn',
  })]),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const client = {
  530: clientFX,
  7000: clientZETA
}

export const healthClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://graph-node-index.functionx.io/graphql',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const stakingClient = new ApolloClient({
  link: new HttpLink({
    uri: 'https://api.thegraph.com/subgraphs/name/way2rach/talisman',
  }),
  cache: new InMemoryCache(),
  shouldBatch: true,
})

export const blockClientFX = new ApolloClient({
  link: from([retryLink, errorLink, new HttpLink({
    uri: 'https://graph-node.functionx.io/subgraphs/name/subgraphFXEVM_Blocks_mainnet',
    // uri: 'https://api.goldsky.com/api/public/project_clz6ti9bm1qjm01086dflgsh3/subgraphs/blocksubgraph/1.0.0/gn',
  })]),
  cache: new InMemoryCache(),
})

export const blockClientZETA = new ApolloClient({
  link: from([retryLink, errorLink, authMiddleware, new HttpLink({
    // uri: 'https://graph-node.functionx.io/subgraphs/name/subgraphFXEVM_Blocks_mainnet',
    uri: 'https://api.goldsky.com/api/private/project_clz6ti9bm1qjm01086dflgsh3/subgraphs/blocksubgraph/1.0.0/gn',
  })]),
  cache: new InMemoryCache(),
})

export const blockClient = {
  530: blockClientFX,
  7000: blockClientZETA
}
