import React, { useState, useEffect } from 'react'
import { useMedia } from 'react-use'
import dayjs from 'dayjs'
import utc from 'dayjs/plugin/utc'
import { Box, Flex, Text } from 'rebass'
import styled from 'styled-components'

import { CustomLink } from '../Link'
import { Divider } from '..'
import { withRouter } from 'react-router-dom'
import { formatTime, formattedNum } from '../../utils'
import { TYPE } from '../../Theme'

dayjs.extend(utc)

const PageButtons = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 2em;
  margin-bottom: 0.5em;
`

const Arrow = styled.div`
  color: ${({ theme }) => theme.primary1};
  opacity: ${(props) => (props.faded ? 0.3 : 1)};
  padding: 0 20px;
  user-select: none;
  :hover {
    cursor: pointer;
  }
`

const List = styled(Box)`
  -webkit-overflow-scrolling: touch;
`

const DashGrid = styled.div`
  display: grid;
  grid-gap: 1em;
  grid-template-columns: 20px 1.6fr 1.2fr 0.7fr 0.7fr 0.7fr;
  grid-template-areas: 'number name pair value';
  padding: 0 4px;

  > * {
    justify-content: flex-end;
  }

  @media screen and (max-width: 1250px) {
    grid-template-columns: 20px 1.6fr 0.7fr 0.7fr 0.7fr;
    grid-template-areas: 'number name pair value';
  }

  @media screen and (max-width: 1080px) {
    grid-template-columns: 20px 1.5fr 0.7fr 0.7fr 0.7fr;
    grid-template-areas: 'number name pair value';
  }

  @media screen and (max-width: 950px) {
    grid-template-columns: 20px 1fr 1fr 1fr 1fr;
    grid-template-areas: 'name pair value';
  }

  @media screen and (max-width: 600px) {
    grid-template-columns: 1fr 1fr 1fr 1fr;
    grid-template-areas: 'name pair value';
  }
`

const ListWrapper = styled.div``

const ClickableText = styled(Text)`
  color: ${({ theme }) => theme.text1};
  &:hover {
    cursor: pointer;
    opacity: 0.6;
  }
  text-align: end;
  user-select: none;
`

const DataText = styled(Flex)`
  align-items: center;
  text-align: center;
  color: ${({ theme }) => theme.text1};
  & > * {
    font-size: 14px;
  }

  @media screen and (max-width: 600px) {
    font-size: 13px;
  }
`

const SORT_FIELD = {
  POINTS: 0,
  P7DAYS: 1,
  P30DAYS: 2,
}

const FIELD_TO_VALUE = (field) => {
  switch (field) {
    case SORT_FIELD.POINTS:
      return 'cumulativePoint'
    case SORT_FIELD.P7DAYS:
      return 'oneWeekPoints'
    case SORT_FIELD.P30DAYS:
      return 'oneMonthPoints'
    default:
      return 'cumulativePoint'
  }
}

function PointsList({ historyPoints, disbaleLinks, maxItems = 10 }) {
  const below600 = useMedia('(max-width: 600px)')
  const below950 = useMedia('(max-width: 950px)')
  const below1250 = useMedia('(max-width: 1250px)')

  // pagination
  const [page, setPage] = useState(1)
  const [maxPage, setMaxPage] = useState(1)
  const ITEMS_PER_PAGE = maxItems

  // sorting
  const [sortedColumn, setSortedColumn] = useState(SORT_FIELD.P7DAYS)

  useEffect(() => {
    setMaxPage(1) // edit this to do modular
    setPage(1)
  }, [historyPoints])

  useEffect(() => {
    if (historyPoints) {
      let extraPages = 1
      if (Object.keys(historyPoints).length % ITEMS_PER_PAGE === 0) {
        extraPages = 0
      }
      setMaxPage(Math.floor(Object.keys(historyPoints).length / ITEMS_PER_PAGE) + extraPages)
    }
  }, [ITEMS_PER_PAGE, historyPoints])

  const ListItem = ({ historyPoint, index }) => {
    return (
      <DashGrid style={{ height: '48px' }} disbaleLinks={disbaleLinks} focus={true}>
        {!below600 && (
          <DataText area="number" fontWeight="500">
            {index}
          </DataText>
        )}
        <DataText area="name" fontWeight="500" justifyContent="flex-start">
          <CustomLink style={{ marginLeft: below600 ? 0 : '1rem', whiteSpace: 'nowrap' }} to={'/account/' + historyPoint.id}>
            {below950 ? historyPoint.id.slice(0, 4) + '...' + historyPoint.id.slice(38, 42) : historyPoint.id}
          </CustomLink>
        </DataText>

        {/* {!below1080 && (
          <DataText area="type" justifyContent="flex-end">
            {lp.type}
          </DataText>
        )} */}
        {!below1250 && (
          <DataText>
            {formatTime(historyPoint.lastUpdated)}
          </DataText>
        )}

        <DataText area="value">{formattedNum(historyPoint.oneWeekPoints)}</DataText>
        <DataText area="value">{formattedNum(historyPoint.oneMonthPoints)}</DataText>
        <DataText area="value">{formattedNum(historyPoint.cumulativePoint)}</DataText>
      </DashGrid>
    )
  }

  const pointList =
    historyPoints &&
    historyPoints
      .filter((account) => account.isEoa)
      .sort((accountA, accountB) => {
        return parseFloat(accountA[FIELD_TO_VALUE(sortedColumn)]) >
          parseFloat(accountB[FIELD_TO_VALUE(sortedColumn)])
          ? -1
          : 1
      })
      .slice(ITEMS_PER_PAGE * (page - 1), page * ITEMS_PER_PAGE)
      .map((pt, index) => {
        return (
          <div key={index}>
            <ListItem key={index} index={(page - 1) * 10 + index + 1} historyPoint={pt} />
            <Divider />
          </div>
        )
      })

  return (
    <ListWrapper>
      <DashGrid center={true} disbaleLinks={disbaleLinks} style={{ height: 'fit-content', padding: ' 0 0 1rem 0' }}>
        {!below600 && (
          <Flex alignItems="center" >
            <TYPE.main area="number">#</TYPE.main>
          </Flex>
        )}
        <Flex alignItems="center" justifyContent="flex-start">
          <TYPE.main area="name" style={{ marginLeft: below600 ? 0 : '20px', whiteSpace: 'nowrap' }}>Account</TYPE.main>
        </Flex>
        {/* {!below1080 && (
          <Flex alignItems="center" justifyContent="flexEnd">
            <TYPE.main area="type">Type</TYPE.main>
          </Flex>
        )} */}
        {!below1250 && (
          <Flex alignItems="center" justifyContent="flexEnd">
            <TYPE.main area="pair">Last Updated</TYPE.main>
          </Flex>
        )}

        <Flex alignItems="center" justifyContent="flexEnd">
          <ClickableText
            area="liq"
            onClick={(e) => {
              setSortedColumn(SORT_FIELD.P7DAYS)
            }}
          >
            Points (7d) {sortedColumn === SORT_FIELD.P7DAYS ? '↓' : ''}
          </ClickableText>
        </Flex>
        <Flex alignItems="center" justifyContent="flexEnd">
          <ClickableText
            area="liq"
            onClick={(e) => {
              setSortedColumn(SORT_FIELD.P30DAYS)
            }}
          >
            Points (30d) {sortedColumn === SORT_FIELD.P30DAYS ? '↓' : ''}
          </ClickableText>
        </Flex>
        <Flex alignItems="center" justifyContent="flexEnd">
          <ClickableText
            area="liq"
            onClick={(e) => {
              setSortedColumn(SORT_FIELD.POINTS)
            }}
          >
            Points (All-time) {sortedColumn === SORT_FIELD.POINTS ? '↓' : ''}
          </ClickableText>
        </Flex>
      </DashGrid>
      <Divider />
      <List p={0}>{!historyPoints ? <TYPE.light color="#a9aaab" marginTop="1rem" marginLeft="1rem">No accounts with trading volume greater than 500 USDT were found.</TYPE.light> : pointList}</List>
      {/* <PageButtons>
                <div onClick={() => setPage(page === 1 ? page : page - 1)}>
                    <Arrow faded={page === 1 ? true : false}>←</Arrow>
                </div>
                <TYPE.body>{'Page ' + page + ' of ' + maxPage}</TYPE.body>
                <div onClick={() => setPage(page === maxPage ? page : page + 1)}>
                    <Arrow faded={page === maxPage ? true : false}>→</Arrow>
                </div>
            </PageButtons> */}
    </ListWrapper>
  )
}

export default withRouter(PointsList)
