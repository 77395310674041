import React, { useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import { TYPE } from '../../Theme'
import { Flex } from 'rebass'
import Link from '../Link'
import Row, { RowFixed } from '../Row'
import Logo from '../../assets/marginX_Logo-Dark.svg'
import Icon from '../../assets/icon.svg'
import Wordmark from '../../assets/wordmark_white.svg'
import { withRouter } from 'react-router-dom'
import { BasicLink } from '../Link'
import { useMedia } from 'react-use'
import { Link as RouterLink } from 'react-router-dom'
import { useChainIdManager, useDarkModeManager } from '../../contexts/LocalStorage'
import { ButtonChain } from '../ButtonStyled'
import { AutoColumn } from '../Column'
import { ChainLogo } from '../TokenLogo'
import { Menu } from 'react-feather'
import MenuPopup from '../Menu'
import Toggle from '../Toggle'

const TitleWrapper = styled.div`
  text-decoration: none;
  z-index: 999;
  width: 100%;
  &:hover {
    cursor: pointer;
  }
`

const UniIcon = styled(Link)`
  transition: transform 0.3s ease;
  :hover {
    transform: rotate(-5deg);
  }
`

const StaticIcon = styled(Link)`
  display: flex;
`

const Option = styled.div`
  font-weight: 500;
  font-size: 14px;
  opacity: ${({ activeText }) => (activeText ? 1 : 0.6)};
  color: ${({ theme }) => theme.white};
  display: flex;
  margin-left: 12px;
  :hover {
    opacity: 1;
  }
`

const DropdownWrapper = styled.div`
  position: relative;
  border: 1px solid #787c8a9c;
  border-radius: 12px;
  margin-left: 20px;
  width: 150px;
`

const Flyout = styled.div`
  position: absolute;
  top: 26px;
  left: -1px;
  width: 100%;
  background-color: #212429;
  z-index: 999;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
  padding-top: 8px;
  border: 1px solid #787c8a9c;
  border-top: none;
`

const MenuRow = styled(Row)`
  width: 100%;
  height: 36px;
  padding: 12px 0;
  padding-left: 12px;

  :hover {
    cursor: pointer;
    background-color: #2C2F36;
    border-bottom-right-radius: 10px;
    border-bottom-left-radius: 10px;
  }
`
const ToggleWrapper = styled.div`
  margin-bottom: -5px;
`
const chainIdMap = {
  530: 'F(x)Core',
  7000: 'Zetachain'
}

function Title() {
  // console.log(history)
  const history = useHistory()
  const below1080 = useMedia('(max-width: 1080px)')
  const below800 = useMedia('(max-width: 800px)')
  const [chainId, setChainId] = useChainIdManager()
  const [showDropdown, setShowDropdown] = useState(false)
  const [isDark, toggleDarkMode] = useDarkModeManager()

  const changeChain = (_chainId) => {
    setChainId(_chainId)
    window.location.reload()
  }

  return (
    <TitleWrapper>
      <Flex alignItems="center" style={{ justifyContent: 'space-between' }}>
        <RowFixed>
          {below800 && (
            <MenuPopup />
          )}
          {below800 ?
            <StaticIcon onClick={() => history.push('/')}><img width={'34px'} src={Icon} alt="logo" /></StaticIcon>
            : below1080 ?
              <StaticIcon onClick={() => history.push('/')}><img width={'120px'} src={Logo} alt="logo" /></StaticIcon>
              :
              <UniIcon id="link" onClick={() => history.push('/')}>
                <img width={'c'} src={Logo} alt="logo" />
              </UniIcon>
          }
          {/* {!below1080 && (
            <img width={'84px'} style={{ marginLeft: '8px', marginTop: '0px' }} src={Wordmark} alt="logo" />
          )} */}
        </RowFixed>
        {below1080 && !below800 && (
          <RowFixed style={{ alignItems: 'flex-end' }}>
            <BasicLink to="/home">
              <Option activeText={history.location.pathname === '/home' ?? undefined}>Overview</Option>
            </BasicLink>
            <BasicLink to="/tokens">
              <Option
                activeText={
                  (history.location.pathname.split('/')[1] === 'tokens' ||
                    history.location.pathname.split('/')[1] === 'token') ??
                  undefined
                }
              >
                Tokens
              </Option>
            </BasicLink>

            <BasicLink to="/pairs">
              <Option
                activeText={
                  (history.location.pathname.split('/')[1] === 'pairs' ||
                    history.location.pathname.split('/')[1] === 'pair') ??
                  undefined
                }
              >
                Pairs
              </Option>
            </BasicLink>

            <BasicLink to="/transactions">
              <Option
                activeText={
                  (history.location.pathname.split('/')[1] === 'transactions' ||
                    history.location.pathname.split('/')[1] === 'transactions') ??
                  undefined
                }
              >
                Transactions
              </Option>
            </BasicLink>

            <BasicLink to="/accounts">
              <Option
                activeText={
                  (history.location.pathname.split('/')[1] === 'accounts' ||
                    history.location.pathname.split('/')[1] === 'account') ??
                  undefined
                }
              >
                Accounts
              </Option>
            </BasicLink>

            <BasicLink to="/points">
              <Option
                activeText={
                  (history.location.pathname.split('/')[1] === 'points' ||
                    history.location.pathname.split('/')[1] === 'points') ??
                  undefined
                }
              >
                Points
              </Option>
            </BasicLink>
          </RowFixed>
        )}
        {below1080 && (
          <RowFixed>
            <ToggleWrapper><Toggle isActive={isDark} toggle={toggleDarkMode} /></ToggleWrapper>
            <DropdownWrapper>
              <ButtonChain width="100%" height="35px" style={{ "padding": '5px 12px', backgroundColor: "#2C2F36", color: "white" }} onClick={() => setShowDropdown(!showDropdown)} open={showDropdown}>
                <RowFixed>
                  {!showDropdown && <ChainLogo chainId={chainId} />}
                  <TYPE.body color={'white'} ml={'5px'}>
                    {showDropdown ? ' Select Network' : chainIdMap[chainId]}
                  </TYPE.body>
                </RowFixed>
              </ButtonChain>
              {showDropdown && (
                <Flyout>
                  <AutoColumn gap="0px">
                    <MenuRow
                      onClick={() => {
                        setShowDropdown(false)
                        changeChain(530)
                      }}
                    >
                      <ChainLogo chainId={530} />
                      <TYPE.body color={'white'} ml={'16px'}>
                        F(x)Core
                        {chainId === 530 && '  ✓'}
                      </TYPE.body>
                    </MenuRow>
                    <MenuRow
                      onClick={() => {
                        setShowDropdown(false)
                        changeChain(7000)
                      }}
                    >
                      <ChainLogo chainId={7000} />
                      <TYPE.body color={'white'} ml={'16px'}>
                        Zetachain
                        {chainId === 7000 && '  ✓'}
                      </TYPE.body>
                    </MenuRow>
                  </AutoColumn>
                </Flyout>
              )}
            </DropdownWrapper>
          </RowFixed>
        )}
      </Flex>
    </TitleWrapper>
  )
}

export default Title