import React, { useCallback, useEffect, useState } from 'react'
import { Menu, TrendingUp, List, PieChart, Disc, Pocket, Hash, RefreshCw } from 'react-feather'
import { useHistory } from 'react-router-dom'
import styled from 'styled-components'
import Popover, { PopoverProps } from '../Popover'
import { AutoColumn } from '../Column'
import Row, { RowBetween } from '../Row'
import { TYPE } from '../../Theme'
import Link, { BasicLink } from '../Link'

const QuestionWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.2rem;
  border: none;
  background: none;
  outline: none;
  cursor: pointer;
  border-radius: 36px;
  background-color: transparent;
  color: ${({ theme }) => theme.text2};

  :hover,
  :focus {
    opacity: 0.7;
  }
`

const TooltipContainer = styled.div`
  width: 150px;
  line-height: 150%;
  font-weight: 500;
  font-size: 1rem;
  background-color: #21252a;
  border-radius: 8px;
  padding: 5px 0px 5px;
`

const Option = styled.div`
  font-weight: 500;
  font-size: 14px;
  height: 26px;
  color: white;
  display: flex;
  opacity: ${({ activeText }) => (activeText ? 1 : 0.6)};
  padding: 7px 0px 7px 15px;
  align-items: center;
  :hover {
    opacity: 0.8;
    background-color: #2C2F36;
  }
`


export function Tooltip({ close, ...rest }) {
    const history = useHistory()
    useEffect(() => {
        close()
    }, [history.location.pathname])
    return (
        <Popover content={
            <TooltipContainer>
                <AutoColumn>
                    <BasicLink to="/home">
                        <Option activeText={history.location.pathname === '/home' ?? undefined}>
                            <TrendingUp size={14} style={{ marginRight: '.5rem' }} />
                            <TYPE.body color={'white'} fontSize={14}>Overview</TYPE.body>
                        </Option>
                    </BasicLink>
                    <Link href="https://swap.marginx.io/" target="_blank">
                        <Option activeText={undefined}>
                            <RefreshCw size={14} style={{ marginRight: '.5rem' }} />
                            Swap
                        </Option>
                    </Link>
                    <BasicLink to="/tokens">
                        <Option
                            activeText={
                                (history.location.pathname.split('/')[1] === 'tokens' ||
                                    history.location.pathname.split('/')[1] === 'token') ??
                                undefined
                            }
                        >
                            <Disc size={14} style={{ marginRight: '.5rem' }} />
                            <TYPE.body color={'white'} fontSize={14}>Tokens</TYPE.body>
                        </Option>
                    </BasicLink>
                    <BasicLink to="/pairs">
                        <Option
                            activeText={
                                (history.location.pathname.split('/')[1] === 'pairs' ||
                                    history.location.pathname.split('/')[1] === 'pair') ??
                                undefined
                            }
                        >
                            <PieChart size={14} style={{ marginRight: '.5rem' }} />
                            <TYPE.body color={'white'} fontSize={14}>Pairs</TYPE.body>
                        </Option>
                    </BasicLink>
                    <BasicLink to="/transactions">
                        <Option
                            activeText={
                                (history.location.pathname.split('/')[1] === 'transactions' ||
                                    history.location.pathname.split('/')[1] === 'transactions') ??
                                undefined
                            }
                        >
                            <List size={14} style={{ marginRight: '.5rem' }} />
                            <TYPE.body color={'white'} fontSize={14}>Transactions</TYPE.body>
                        </Option>
                    </BasicLink>
                    <BasicLink to="/accounts">
                        <Option
                            activeText={
                                (history.location.pathname.split('/')[1] === 'accounts' ||
                                    history.location.pathname.split('/')[1] === 'account') ??
                                undefined
                            }
                        >
                            <Pocket size={14} style={{ marginRight: '.5rem' }} />
                            <TYPE.body color={'white'} fontSize={14}>Accounts</TYPE.body>
                        </Option>
                    </BasicLink>
                    <BasicLink to="/points">
                        <Option
                            activeText={
                                (history.location.pathname.split('/')[1] === 'points' ||
                                    history.location.pathname.split('/')[1] === 'points') ??
                                undefined
                            }
                        >
                            <Hash size={14} style={{ marginRight: '.5rem' }} />
                            <TYPE.body color={'white'} fontSize={14}>Points</TYPE.body>
                        </Option>
                    </BasicLink>
                </AutoColumn>
            </TooltipContainer>
        } {...rest} />
    )
}
// <Menu size={24} color='white' style={{ marginRight: '1rem', marginLeft: '0px' }} />
export default function MenuPopup({ text, disabled }) {
    const [show, setShow] = useState(false)

    const open = useCallback(() => setShow(true), [setShow])
    const close = useCallback(() => setShow(false), [setShow])

    return (
        <span style={{ marginLeft: '2px', marginRight: '0.3rem', width: '35px', height: '40px', marginBottom: '-10px' }} onMouseEnter={open} onMouseLeave={close} onTouchStart={open}>
            <Tooltip close={close} show={show && !disabled} placement='bottom'>
                <QuestionWrapper>
                    <Menu size={24} color='white' style={{ marginLeft: '0px' }} />
                </QuestionWrapper>
            </Tooltip>
        </span>
    )
}
